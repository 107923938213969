import React from "react";
import {Period} from "./Period";
import clsx from "clsx";
import {generateBorderColor} from "../util/colors";
import {Section} from "./Section";
import {graphql, Link, useStaticQuery} from "gatsby";
import {routes} from "../util/routes";
import {useMatch} from "@reach/router";

// TODO MAKE SURE SITEMAP WORKS
const Watchlist = () => {
    const match = useMatch(routes.anime);
    const watchlist = useStaticQuery<GatsbyTypes.AllAnimeWatchlistQuery>(graphql`
    query AllAnimeWatchlist {
      allAnimeWatchlist {
        nodes {
          data {
            node {
              title
              main_picture {
                medium
              }
            }
          }
        }
      }
    }
  `);

    return (
        <Section>
            <div className="flex">
                <header>
                    <p className="mb-0 text-lg text-yellow-500 font-semibold">
                        My Watchlist
                    </p>
                    <h2 className="text-2xl font-bold md:text-3xl dark:text-gray-300 mb-8">
                        My Future Watches
                        <Period/>
                    </h2>
                </header>

                {
                    !match &&  <Link
                        className="hidden md:inline-flex font-medium hover:bg-blue-500 hover:text-white py-2 px-2 group rounded items-center text-blue-500 focus:ring-4 ml-auto my-auto dark:text-gray-300"
                        to={routes.anime}
                    >
                        Check out my cultured tastes
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-7 w-7 ml-2 text-blue-500 group-hover:text-white"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </Link>
                }            </div>

            <div className="grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-10">
                {watchlist.allAnimeWatchlist.nodes[0].data.map((anime, index) => {
                    return (
                        <div
                            className={clsx(
                                "flex shadow-lg rounded-md border-b-8 dark:bg-gray-900",
                                generateBorderColor(index)
                            )}
                            key={anime.node.title}
                        >
                            <img
                                loading="lazy"
                                className="h-52 rounded-tl-md"
                                width={150}
                                height={208}
                                src={anime.node.main_picture.medium}
                                alt={anime.node.title}
                            />
                            <div className="flex flex-col">
                                <div className="flex flex-col p-5">
                                    <div className="flex justify-between items-center my-auto">
                                        <h3 className="text-xl md:text-2xl font-bold my-auto dark:text-gray-300">
                                            {anime.node.title}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="mt-7 text-center block md:hidden">
                <Link to={routes.anime} className="flex justify-center w-full mx-auto text-center py-2 px-3 bg-yellow-500 text-white text-sm font-semibold rounded-md shadow focus:outline-none">
                    Check out my cultured tastes
                </Link>
            </div>
        </Section>
    );
};

export {Watchlist};
