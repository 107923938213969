import {Star} from "../components/Star";
import * as React from "react";

const convertRatingToStars = (rating: number) => {
    const goldStars = (rating * 5 / 10);
    const silverStars = Math.floor(5 - Math.floor(goldStars));

    return <>
        {
            Array.from(Array(Math.floor(goldStars)), (val, index) => {
                return <Star gold key={index + "gold"}/>
            })
        }
        {
            Array.from(Array(Math.floor(silverStars)), (val, index) => {
                return <Star key={index + "silver"}/>
            })
        }
    </>
}

export {convertRatingToStars}
