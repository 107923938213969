import React from "react";
import {Period} from "./Period";
import clsx from "clsx";
import {generateBorderColor} from "../util/colors";
import {convertRatingToStars} from "../util/convertRatingToStarts";
import {Section} from "./Section";
import {graphql, Link, useStaticQuery} from "gatsby";
import {routes} from "../util/routes";
import {useMatch} from "@reach/router";

const MangaList = () => {
  const match = useMatch(routes.anime);
  const mangaData = useStaticQuery<GatsbyTypes.AllMangaQuery>(graphql`
    query AllManga {
      allManga {
        nodes {
          id
          data {
            node {
              title
              main_picture {
                medium
              }
            }
            list_status {
              score
            }
          }
        }
      }
    }
  `);

  return (
    <Section className="dark:text-gray-300">
      <div className="flex">
        <header>
          <p className="mb-0 text-lg text-pink-500 font-semibold">
            Top Loved Manga
          </p>
          <h2 className="mb-8 text-2xl md:text-3xl font-extrabold leading-tight  dark:text-gray-300">
            My Favorite Manga
            <Period />
          </h2>
        </header>

        {!match && (
          <Link
            className="hidden md:inline-flex font-medium hover:bg-blue-500 hover:text-white py-2 px-2 group rounded items-center text-blue-500 focus:ring-4 ml-auto my-auto dark:text-gray-300"
            to={routes.anime}
          >
            Check out my cultured tastes
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7 ml-2 text-blue-500 group-hover:text-white"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        )}
      </div>

      <div className="grid grid-cols-2 gap-12 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 justify-center md:gap-10 gap-y-10">
        {mangaData.allManga.nodes[0].data.map((manga, index) => {
          return (
            <div className="rounded-md" key={manga.node.title}>
              <img
                loading="lazy"
                width={158}
                height={208}
                className={clsx(
                  "h-52 border-l-8 border shadow-md rounded-md mx-auto",
                  generateBorderColor(index)
                )}
                src={manga.node.main_picture.medium}
                alt={manga.node.title}
              />

              <div className="flex flex-col p-2 text-center justify-center">
                <div className="justify-between items-center">
                  <h3 className="lg:text-xl font-bold text-center">
                    {manga.node.title}
                  </h3>
                </div>
                <div className="flex gap-2 items-center mt-1 mb-2 mx-auto">
                  {
                    // @ts-ignore
                    convertRatingToStars(
                      manga.list_status.score,
                      manga.node.title
                    )
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>

        <div className="mt-7 text-center block md:hidden">
            <Link to={routes.anime} className="flex justify-center w-full mx-auto text-center py-2 px-3 bg-pink-500 text-white text-sm font-semibold rounded-md shadow focus:outline-none">
                Check out my cultured tastes
            </Link>
        </div>
    </Section>
  );
};

export { MangaList };
