import React from "react";
import clsx from "clsx";

const Section = (props) => {
    return (
        <section className={clsx(props.className, "max-w-6xl 2xl:max-w-7xl mx-auto container mb-28 2xl:px-20 px-5")}>
            {props.children}
        </section>
    );
}

export {Section}
