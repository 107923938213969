import clsx from "clsx";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useContext} from "react";
import {Breadcrumb} from "../../components/Breadcrumb";
import {Comments} from "../../components/Comments";
import {MangaList} from "../../components/MangaList";
import {TopRatedAnime} from "../../components/pages/home/TopRatedAnime";
import {Period} from "../../components/Period";
import {Section} from "../../components/Section";
import {Seo} from "../../components/Seo";
import {Star} from "../../components/Star";
import {Watchlist} from "../../components/Watchlist";
import ThemeContext from "../../context/ThemeContext";
import {generateRandomColor} from "../../util/colors";
import {PageHeader} from "../../components/PageHeader";

const Anime = ({location}) => {
    const {dark, setDark, toggleDark} = useContext(ThemeContext);

    const allCharacters =
        useStaticQuery<GatsbyTypes.GetAllArticlesQueryQuery>(graphql`
      query GetAllArticlesQuery {
        allGraphCmsCharacter(filter: { stage: { eq: PUBLISHED } }) {
          edges {
            node {
              type
              headshot {
                gatsbyImageData(placeholder: BLURRED, quality:70, width: 200, height: 200)
                alt
              }
              excerpt
              name
            }
          }
        }
      }
    `);

    return (
        <>
            <Seo
                title="Anime"
                description="My curated and cultured anime tastes. Take a look as long as you don't get mad when I trash your fave. If you like anime, share some suggestions with me!"
            />
            <Breadcrumb activeLabel="Anime"/>

            <PageHeader
                title="Anime"
                periodColor={generateRandomColor(4)}
                description="My curated and cultured anime taste"
            />

            <Section>
                <header>
                    <p className="mb-0 text-lg text-blue-600 font-semibold">The Best</p>
                    <h2 className="text-xl font-bold md:text-3xl dark:text-gray-300 mb-10">
                        My Favorite Characters
                        <Period/>
                    </h2>
                </header>

                <div className="grid gap-5 gap-y-10 grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                    {allCharacters.allGraphCmsCharacter.edges.map((node, index) => {
                        if (node.node.type == "GOOD") {
                            return (
                                <div className="px-12 text-center sm:px-0 flex flex-col" key={node.node.name}>
                                    <GatsbyImage
                                        loading="lazy"
                                        imgClassName="rounded-full"
                                        className="flex items-center justify-center bg-center bg-cover bg-no-repeat w-32 h-32 mx-auto mb-4 rounded-full border-4 border-blue-500 shadow"
                                        alt={node.node.headshot.alt}
                                        image={node.node.headshot.gatsbyImageData}
                                    />
                                    <h3
                                        className={clsx(
                                            "mb-2 text-sm font-bold leading-5 tracking-wider uppercase gap-1 dark:text-gray-400 text-center",
                                            index == 0 && "inline-flex items-center mx-auto"
                                        )}
                                    >
                                        {node.node.name} {index == 0 && <Star gold/>}
                                    </h3>
                                    <p className="mb-2 text-md text-left dark:text-gray-300 mx-auto">
                                        {node.node.excerpt}
                                    </p>
                                </div>
                            );
                        }
                    })}
                </div>
            </Section>

            <Section>
                <header>
                    <p className="mb-0 text-lg text-red-500 font-semibold">The Worst</p>
                    <h2 className="text-xl font-bold md:text-3xl dark:text-gray-300 mb-10">
                        Bad Characters
                        <Period/> 🤮
                    </h2>
                </header>

                <div className="grid gap-5 gap-y-10 grid-cols-1 sm:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                    {allCharacters.allGraphCmsCharacter.edges.map((node, index) => {
                        if (node.node.type == "BAD") {
                            return (
                                <div className="px-12 text-center sm:px-0" key={node.node.name}>
                                    <GatsbyImage
                                        loading="lazy"
                                        imgClassName="rounded-full"
                                        className="flex items-center justify-center bg-center bg-cover bg-no-repeat w-32 h-32 mx-auto mb-4 rounded-full border-4 border-red-500 shadow"
                                        alt={node.node.headshot.alt}
                                        image={node.node.headshot.gatsbyImageData}
                                    />
                                    <h3
                                        className={clsx(
                                            "mb-2 text-sm font-bold leading-5 tracking-wider uppercase gap-1 dark:text-gray-400",
                                            index == 0 && "inline-flex items-center"
                                        )}
                                    >
                                        {node.node.name} {index == 0 && <Star gold/>}
                                    </h3>
                                    <p className="mb-2 text-base text-left dark:text-gray-300 mx-auto">
                                        {node.node.excerpt}
                                    </p>
                                </div>
                            );
                        }
                    })}
                </div>
            </Section>

            <TopRatedAnime/>

            <Watchlist/>

            <MangaList/>

            <Section className="dark:text-gray-300">
                <p className="mb-0 text-lg text-pink-500 font-semibold">
                    Got a comment
                </p>
                <h2 className="mb-8 text-2xl md:text-3xl font-extrabold leading-tight  dark:text-gray-300">
                    Leave Suggestions or Arguments Below
                    <Period/>
                </h2>

                <Comments slug="anime" darkMode={dark}/>
            </Section>
        </>
    );
};

export default Anime;
