import * as React from "react";
import {Period} from "../../Period";
import {Anime} from "../../../types";
import clsx from "clsx";
import {generateBgColor, generateBorderColor} from "../../../util/colors";
import {convertRatingToStars} from "../../../util/convertRatingToStarts";
import {Section} from "../../Section";
import {graphql, Link, useStaticQuery} from "gatsby";
import {routes} from "../../../util/routes";
import {useMatch} from "@reach/router";

const TopRatedAnime = () => {
    const match = useMatch(routes.anime);

    const topRatedAnime = useStaticQuery<GatsbyTypes.TopRatedAnimeQuery>(graphql`
    query TopRatedAnime {
      allAnime {
        nodes {
          data {
            node {
              title
              review
              main_picture {
                medium
              }
            }
            list_status {
              score
            }
          }
        }
      }
    }
  `);

    return (
        <>
            <Section className="dark:text-gray-300">
                <div className="flex">
                    <header>
                        <p className="mb-0 text-lg text-green-500 font-semibold">
                            Top Rated Anime
                        </p>
                        <h2 className="mb-8 text-2xl md:text-3xl font-extrabold leading-tight dark:text-gray-300">
                            My Anime Recommendation List
                            <Period/>
                        </h2>
                    </header>

                    {
                        !match &&  <Link
                            className="hidden md:inline-flex font-medium hover:bg-blue-500 hover:text-white py-2 px-2 group rounded items-center text-blue-500 focus:ring-4 ml-auto my-auto dark:text-gray-300"
                            to={routes.anime}
                        >
                            Check out my cultured tastes
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-7 w-7 ml-2 text-blue-500 group-hover:text-white"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Link>
                    }
                </div>

                <div className="mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-5">
                        {
                            topRatedAnime.allAnime.nodes[0].data.map((anime: Anime, index: number) => {
                                return (
                                    <div
                                        className={clsx(
                                            "flex rounded-md border-t-8",
                                            generateBorderColor(index)
                                        )}
                                        key={anime.node.title}
                                    >
                                        <div className={clsx("w-full relative")}>
                                            <div
                                                className="bg-white dark:bg-gray-900 shadow-lg min-h-52 p-5 flex space-x-5 h-full rounded-md">
                                                <div>
                                                    <img
                                                        loading="lazy"
                                                        width={120}
                                                        height={218}
                                                        className="max-h-60 rounded-lg shadow-lg float-right"
                                                        src={anime.node.main_picture.medium}
                                                        style={{
                                                            height: "13rem !important",
                                                            width: "auto !important",
                                                        }}
                                                        alt={anime.node.title}
                                                    />
                                                </div>
                                                <div className="flex flex-col w-3/4">
                                                    <div className="flex flex-col">
                                                        <div className="flex justify-between items-center">
                                                            <h3 className="text-md font-bold">
                                                                {anime.node.title}
                                                            </h3>
                                                        </div>
                                                        <div className="flex gap-2 items-center mt-1 mb-2">
                                                            {
                                                                // @ts-ignore
                                                                convertRatingToStars(
                                                                    anime.list_status.score,
                                                                    anime.node.title
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className={clsx(
                                                                "absolute w-2 h-2 text-white rounded-full flex justify-center items-center text-center p-4 left-1 top-2 font-medium",
                                                                generateBgColor(index)
                                                            )}
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    </div>

                                                    <p className="text-base m-0">{anime.node.review}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <div className="mt-7 text-center block md:hidden">
                    <Link to={routes.anime}  className="flex justify-center w-full mx-auto text-center py-2 px-3 bg-green-500 text-white text-sm font-semibold rounded-md shadow focus:outline-none">
                        Check out my cultured tastes
                    </Link>
                </div>
            </Section>
        </>
    );
};

export {TopRatedAnime};
