import React, {useContext, useEffect, useRef} from "react";
import ThemeContext from "../context/ThemeContext";

// TODO FIX COMMENTS DARK MODE
const Comments = ({ slug }) => {
  const { dark } = useContext(ThemeContext);
  const commentBoxRef = useRef();
  
  // TODO CHECK IF COMMENTS RELOAD USING THE DEP ARR and make client side only
  useEffect(() => {
    const scriptParentNode = document.getElementById("comments");
    if (!scriptParentNode) return;

    const scriptTag = document.createElement("script");
    scriptTag.setAttribute("src", "https://utteranc.es/client.js");
    scriptTag.setAttribute("async", "true");
    scriptTag.setAttribute("repo", process.env.GATSBY_GITHUB_COMMENT_REPO);
    scriptTag.setAttribute("issue-term","pathname");
    scriptTag.setAttribute("theme", dark ? "github-dark" : "github-light");
    scriptTag.setAttribute("crossorigin", "anonymous");

    scriptParentNode.appendChild(scriptTag)
    // if (commentBoxRef && commentBoxRef.current) {
    //   commentBoxRef.current.appendChild(scriptTag);
    // }

    return () => {
      scriptParentNode.removeChild(scriptParentNode.firstChild as Node);
    }
  }, [slug]);

  return <div id="comments" className="w-full" ref={commentBoxRef} />;
};

export { Comments };
